import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  static targets = ['statusLabel']

  statusLabelTargetConnected(element) {
    this.init_popup(element)
  }

  init_popup(element) {
    $(element).popup({
      popup: $(this).data('popup-id'),
      hoverable: true,
      position: 'left center',
      delay: {
        show: 300,
        hide: 800
      }
    });

    $(element).find('.cancel.button').on('click', function() {
      return $.ajax({
        url: "https://datamover1.imagion.de/tasks/" + $(this).attr("data-task-id") + "/cancel",
        type: "GET",
        dataType: "json",
        data: {
          token: $(this).attr("data-token")
        },
        success: function(data, _textStatus, _xhr) {
          return console.log(data);
        },
        error: function(_jqXHR, _textStatus, errorThrown) {
          return console.log(errorThrown);
        }
      });
    });
  }

}
