import { Controller } from "@hotwired/stimulus"
import { get, post } from '@rails/request.js'

export default class extends Controller {

  static targets = [
    "sourceAssets",
    "webvttElement",
    "standardProxyPlayerPosition",
    "vidcheckerProxyPlayerPosition",
    'vidcheckerGrid',
    'croppingMenu',
    'vidcheckerEvents',
    'proxyPlayer',
    'enableCroppingDisplayButton',
    'disableCroppingDisplayButton',
    'vidcheckerEventsDropdown',
    'vidcheckerAltertLevelsDropdown',
    'vidcheckerAltertTypesDropdown',
    "locationLabel",
    'tagCountLabel',
    'commentInput',
    'commentButton',
  ]

  static outlets = [
    'proxy-video'
  ]

  connect() {
    this.updateTagCount()
  }

  // disconnect() {
  //   console.log("disconnect")
  // }

  sourceAssetsTargetConnected(_element) {
    if (document.getElementById('import_cropping_values')) {
      this.checkSourcesForCroppingValues()
    }
  }
  
  locationLabelTargetConnected(element) {
    this.loadLocationLabel(element)
  }

  // commentInputTargetConnected(element) {
  //   console.log(element)
  // }

  async sendComment() {
    const input = $(this.commentInputTarget)
    const button = $(this.commentButtonTarget)
    const comment = input.val()
    //console.log(comment)

    button.addClass("loading")
    input.addClass("disabled")

    const response = await post(`/assets/${this.asset_id()}/comment`, {
      body: {
        comment: comment,
      }
    })

    if (response.ok) {
      input.val("")
      button.removeClass("loading")
      input.removeClass("disabled")
      const result = await response.json
      //console.log(result)
      return result
    } else {
      button.removeClass("loading")
      input.removeClass("disabled")
    }
  }

  enableCroppingDisplay() {
    this.moveProxyPlayer('vidchecker')
    $(this.croppingMenuTargets).show()
    $(this.enableCroppingDisplayButtonTargets).addClass('active')
    $(this.disableCroppingDisplayButtonTargets).removeClass('active')
    $(this.vidcheckerEventsTargets).removeClass('sixteen wide column').addClass('eight wide column')
  
    this.limitVideoControls(true)
  }

  limitVideoControls(limit) {
    if (this.hasProxyVideoOutlet) {
      limit ? this.proxyVideoOutlet.hideControlsValue = 'lock subtitle captions airplay chromecast audio settings qc' : this.proxyVideoOutlet.hideControlsValue = ''

      this.proxyVideoOutlet.resetCropOverlay()
    }
  }

  selectVidcheckerCroppingReport() {
    const c = this
    const matched_items = $(c.vidcheckerEventsDropdownTargets).parent('.dropdown').find('.menu .item').filter((_, element) => /vidchecker.*video/i.test($(element).data('text')))
    let item_value = matched_items.first().data('value')
    if (item_value) $(c.vidcheckerEventsDropdownTargets).dropdown('set selected', item_value)
    
    $(c.vidcheckerAltertLevelsDropdownTargets).dropdown('set exactly', 3)
    $(c.vidcheckerAltertTypesDropdownTargets).dropdown('queryRemote', '', function() {
      $(c.vidcheckerAltertTypesDropdownTargets).dropdown('set exactly', '621')
    })
  }

  disableCroppingDisplay() {
    this.moveProxyPlayer('standard')
    $(this.croppingMenuTargets).hide()
    $(this.enableCroppingDisplayButtonTargets).removeClass('active')
    $(this.disableCroppingDisplayButtonTargets).addClass('active')
    $(this.vidcheckerEventsTargets).addClass('sixteen wide column').removeClass('eight wide column')
    
    this.limitVideoControls(false)
  }

  moveProxyPlayer(position) {
    if (!this.hasProxyPlayerTarget) return

    switch(position) {
      case 'standard':
        if (this.hasStandardProxyPlayerPositionTarget) {
          this.standardProxyPlayerPositionTarget.append(this.proxyPlayerTarget)
        }
        $(this.proxyPlayerTarget).accordion('setting', 'duration', 0)
        $(this.proxyPlayerTarget).accordion('close', 0)
        $(this.proxyPlayerTarget).accordion('setting', 'duration', 350)
        break
      case 'vidchecker':
        if (this.hasVidcheckerProxyPlayerPositionTarget) {
          this.vidcheckerProxyPlayerPositionTarget.append(this.proxyPlayerTarget)
        }
        $(this.proxyPlayerTarget).accordion('setting', 'duration', 0)
        $(this.proxyPlayerTarget).accordion('open', 0)
        $(this.proxyPlayerTarget).accordion('setting', 'duration', 350)
        break
      default:
        break
    }
  }

  toggleVideoPlayer() {    
    if (this.hasStandardProxyPlayerPositionTarget && this.hasProxyPlayerTarget && !this.standardProxyPlayerPositionTarget.contains(this.proxyPlayerTarget)) {
      this.disableCroppingDisplay()
      $(this.proxyPlayerTarget).accordion('open', 0)
      return
    }

    if (this.hasProxyVideoOutlet) {
      if (this.proxyVideoOutlet.visible) {
        $(this.proxyPlayerTarget).accordion('close', 0)
      } else {
        $(this.proxyPlayerTarget).accordion('open', 0)
      }
    } else {
      $(this.proxyPlayerTarget).accordion('toggle', 0)
    }
  }

  async checkSourcesForCroppingValues() {
    const source_asset_ids = [...document.querySelectorAll("#source-assets-list fieldset:not([style*='display:none']) .asset-id-display")].map(i => parseInt(i.dataset.assetId))
    const asset_width = document.getElementById('asset_a_FormatWidth').value
    const asset_height = document.getElementById('asset_a_FormatHeight').value

    const response = await get('/assets/cropping_values_for_source_ids', { 
      query: {
        source_asset_ids: source_asset_ids,
        asset_width: asset_width,
        asset_height: asset_height
      }
    })
    if (response.ok) {
      const croppingParams = await response.json
      const importButton = document.getElementById('import_cropping_values')

      importButton.style.display = 'block'
      importButton.dataset.tooltip = `Import from source: ${croppingParams.a_ID}`
      importButton.dataset.assetCroppingParam = JSON.stringify(croppingParams)
    }
  }

  importConfirmedCroppingValues(event) {
    this.setCroppingValues(event.params.cropping)
  }

  asset_id() {
    return $("#asset_a_ID").val()
  }

  workflowsLink() {
    Turbo.visit("/workflows/edit?asset_ids[]=" + this.asset_id())
  }

  openTagsModal(event) {
    let button = event.target.closest('.button')
    button.classList.add('disabled')
    button.style.pointerEvents = 'none'

    let added_tags = $('#asset_asset_tag_ids').val()?.split(/,|\s/).filter(t => t)
    let params = new URLSearchParams()
    added_tags.forEach(t => params.append('asset_tag_ids[]', t))

    let url = this.asset_id() ? `/assets/${this.asset_id()}/asset_tags_modal` : `/assets/asset_tags_modal`

    fetch(`${url}?${params}`, { headers: { Accept: 'text/vnd.turbo-stream.html' } })
      .then(response => response.text())
      .then(html => { Turbo.renderStreamMessage(html) })
      .finally(() => {
        button.classList.remove('disabled')
        button.style.pointerEvents = ''
      })
  }

  openQuicklinkModal() {
    quicklinks.load_modal([this.asset_id()])
  }

  setCroppingValues(cropping) {
    for (const [key, value] of Object.entries(cropping)) {
      if (key != 'a_ID') {
        let element = document.getElementById(`asset_${key}`)
        if (value !== parseInt(element.value)) {
          element.value = value
          element.classList.add('diff')
          element.dispatchEvent(new Event('change'))
          setTimeout(() => { element.classList.remove('diff') }, 800)
        }
      }
    }
  }

  webvttElementTargetConnected() {
    assets.subtitle_check()
  }

  switchPreview() {
    if ($('#proxy_preview').is(":hidden")) {
      $('#proxy_preview').show()
      $('#waveform').hide()
    } else {
      $('#proxy_preview').hide()
      $('#waveform').show()
    }
    $('.switch .icon').toggleClass('muted')
  }

  showWaveformModal() {
    $('.waveform.modal .buttons .button').off('click')
    $('.waveform.modal .image').off('click')

    $('.waveform.modal .buttons .button').on('click', function() {
      $(this).addClass('teal active').siblings().removeClass('teal active')
      $('.waveform.modal .image img').attr('src', $(this).data('src-link'))
    })
    $('.waveform.modal .image').on('click', () => $('.waveform.modal .buttons .button').not('.active').trigger('click'))
  
    $('.waveform.modal').modal('show')
  }

  tagsNestedFields(node) {
    $(node).off("fields_added.nested_form_fields")
    $(node).on("fields_added.nested_form_fields", function(event, param) {
      switch (param.object_class) {
        case "asset_asset_tag":
          let tag_element = $(event.target).find("#asset-tag-")
          
          $(`#asset_asset_asset_tags_attributes_${param.added_index}_atag_tag_ID`).val(param.additional_data.asset_tag.id)
          tag_element.attr("data-asset-tag-tag-id-value", param.additional_data.asset_tag.id)
          tag_element.attr("id", "asset-tag-" + param.additional_data.asset_tag.id)
          return
      }
    })
  }

  showLocationSettingsModal() {
  }

  async loadLocationLabel(element) {
    const url = `/assets/${element.dataset.id}/location_label`
    fetch(url)
      .then(response => response.json())
      .then(({ class: classNames, text }) => {
        element.innerHTML = text
        element.classList.remove('basic')
        classNames.split(" ").forEach((className) => element.classList.add(className))
        element.style.display = "block"

        $(element).popup({
          exclusive: true,
          position: 'left center',
          inline: true,
          on: 'click',
          html: '<div style="min-height: 260px; width: 320px;" id="asset-storage-popup"><div class="ui active small centered inline loader"></div></div>',
          delay: {
            show: 500,
            hide: 0
          },
          onShow() {
            $.ajax({
              url: `/assets/${element.dataset.id}/storage`
            }).done(function(result) {
              $("#asset-storage-popup").html(result)

              if ($("#asset-storage-usage-chart").length > 0) {
                assets.fill_asset_storage_usage_chart("asset-storage-usage-chart", element.dataset.id)
              }

              $('#asset-storage-popup .settings.key .cog.icon').off('click')
              $('#asset-storage-popup .settings.key .cog.icon').on('click', function() {
                assets.settings_modal($(this).data('context'))
              })
      
              $('#asset-storage-popup .settings.key').hover((function() {
                $(this).find('.cog.icon').show()
              }), function() {
                $(this).find('.cog.icon').hide()
              })
            })
          }
        })
      })
  }

  filter_by_contact() {
    let checkbox = $('.search.asset_tags .ui.checkbox')
    if (checkbox.checkbox('is checked')) {
      return checkbox.data('contactId')
    } else {
      return ""
    }
  }

  updateTagCount() {
    if (this.hasTagCountLabelTarget) {
      this.tagCountLabelTarget.textContent = $('#asset_asset_tag_ids').val().split(/,|\s/).length
    }
  }
}
