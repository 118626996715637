import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
const humanFormat = require("human-format")

export default class extends Controller {
  
  static values = {
    basketId: Number,
    fileDuration: Number,
    showHuman: Boolean
  }

  static targets = [ "label" ] 

  connect() {
    this.refresh()
  }

  async refresh() {
    this.fileDurationValue = await this.fetchFileDuration()
  }

  fileDurationValueChanged() {
    this.updateLabel()
  }

  async fetchFileDuration() {
    try {
      const response = await get(`/baskets/${this.basketIdValue}/total_file_duration`)
      if (response.ok) {
        const result = await response.json
        return result.duration
      } else {
        throw new Error('An unknown error occurred!')
      }
    } catch(error) {
      console.log(error)
    }
  }

  updateLabel() {
    var timeScale = new humanFormat.Scale({
      milliseconds: 1,
      seconds: 1000,
      minutes: 60000,
      hours: 3600000,
      days: 86400000,
      months: 2592000000,
    })

    const labelText = this.showHumanValue ? humanFormat(this.fileDurationValue, { scale: timeScale }) : this.fileDurationValue
    this.labelTarget.innerText = labelText
  }

  toggleShowHuman() {
    this.showHumanValue = !this.showHumanValue
    this.updateLabel()
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.fileDurationValue)
    $('body').toast({ message: `Copied File Duration to Clipboard!`, class: 'success' })
  }
}
