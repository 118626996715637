document.addEventListener("turbo:load", function() {
  if ($("#datamover").length > 0) {
    data_mover.init();
  }
});

var data_mover = {
  init() {
    if ($("#datamover").length > 0) {

      if ($("#tasks_in_progress").length > 0) {
        data_mover.set_tasks_in_progress()

        const interval = setInterval((function() {
          if ($("#tasks_in_progress").length > 0) {
            data_mover.set_tasks_in_progress();
          } else {
            clearInterval(interval);
          }
        }), 10000);
        
        $(".check-pool").on('click', function() {
          return $.ajax({
            url: "https://mam.imagion.de/datamovers/checktaskpool.json",
            dataType: "json",
            error: function(_jqXHR, _textStatus, errorThrown) {
              return console.log(errorThrown);
            }
          });
        });
      }
    }
  },

  set_tasks_in_progress() {
    let page = general.get_url_parameter()["page"]

    let url = "/datamover/index"
    if (page != undefined) {
      url += "?page=" + page
    }

    return $.ajax({
      url: url,
      dataType: "script",
      complete: function() {
        context_menu.init();
      }
    });
  },

};

window.data_mover = data_mover;
