import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    'modal',
    'submitButton',
  ]

  connect() {
    // console.log("connect")
    // document.addEventListener("turbo:before-stream-render", function(event) {
    //   console.log("turbo:before-stream-render")
    //   console.log(event)
    // });
  }

  disconnect() {
    
  }
  
  close(event) {
    $(this.modalTarget).modal('hide')
  }

  updateTransferProfile(event) {
    let icon_id = $(event.srcElement).data("icon-id")
    document.getElementById("transfer_profile_info_delivery_order_content_package_component_asset_" + icon_id).reload()
  }
}
