import { Controller } from "@hotwired/stimulus"
//import { BarcodeDetectorPolyfill } from '@undecaf/barcode-detector-polyfill'

export default class extends Controller {

  static detector = null
  static repeat = true

  connect() {
    //console.log("barcode_scanner")
    this.barcode_scanner()
  }

  detect(source) {
    const el = {}

    document
      .querySelectorAll('[id]')
      .forEach(element => el[element.id] = element)

    const
      canvas = el.canvas,
      ctx = canvas.getContext('2d');

    return this.detector
      .detect(source)
      .then(symbols => {
        canvas.width = source.naturalWidth || source.videoWidth || source.width
        canvas.height = source.naturalHeight || source.videoHeight || source.height
        ctx.clearRect(0, 0, canvas.width, canvas.height)

        symbols.forEach(symbol => {
          console.log(symbol)

          const lastCornerPoint = symbol.cornerPoints[symbol.cornerPoints.length - 1]
          ctx.moveTo(lastCornerPoint.x, lastCornerPoint.y)
          symbol.cornerPoints.forEach(point => ctx.lineTo(point.x, point.y))

          ctx.lineWidth = 3
          ctx.strokeStyle = '#00e000ff'
          ctx.stroke()
        })

        if (symbols.length > 0) {
          //console.log(symbols[0].rawValue)
          this.repeat = false
          let code = symbols[0].rawValue
          let asset_id = code.substring(0, code.length - 2)
          //console.log(asset_id)

          Turbo.visit("/assets/" + asset_id + "/edit")
        }

        symbols.forEach(symbol => {
          delete symbol.boundingBox
          delete symbol.cornerPoints
        })

        //console.log(JSON.stringify(symbols, null, 2))
      })
  }

  detectVideo(repeat) {

    const el = {}
    const requestId = null

    this.repeat = repeat

    document
      .querySelectorAll('[id]')
      .forEach(element => el[element.id] = element)

    const
      canvas = el.canvas,
      ctx = canvas.getContext('2d');

    if (!this.repeat) {
      ctx.clearRect(0, 0, canvas.width, canvas.height)
    }

    if (this.repeat) {
      this.detect(el.video)
        .then(() => this.requestId = requestAnimationFrame(() => this.detectVideo(this.repeat)))

    } else {
      cancelAnimationFrame(this.requestId)
      this.requestId = null
    }
  }

  barcode_scanner() {

    const el = {}

    document
      .querySelectorAll('[id]')
      .forEach(element => el[element.id] = element)

    try {
      window['BarcodeDetector'].getSupportedFormats()
    } catch {
      window['BarcodeDetector'] = BarcodeDetectorPolyfill
    }

    //codabar, code_39, code_93, code_128, databar, databar_exp, ean_2, ean_5, ean_8, ean_13, ean_13+2, ean_13+5, isbn_10, isbn_13, isbn_13+2, isbn_13+5, itf, qr_code, sq_code, upc_a, upc_e
    this.detector = new BarcodeDetector({
      formats: ['code_128', 'ean_13', 'qr_code']
    })

    navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          facingMode: 'environment'
        }
      })
      .then(stream => {
        el.video.srcObject = stream
        this.detectVideo(true)
      })
      .catch(error => {
        console.log(error)
      })
  }
}
