import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['dropdown']
  static values = {
    settings: { type: Object, default: { 
      fullTextSearch: true,
      filterRemoteData: true,
      delay: {
        search: 500
      }
    }},
    url: String,
    parameterName: String,
    template: { type: String, default: "" },
    queryParams: { type: Object, default: {} },
  }

  initialize() {
    this.initDropdown()
  }

  initDropdown() {
    const c = this
    let settings = {
      apiSettings: {
        url: c.urlValue,
        method: 'get',
        cache: false,
        beforeSend: c.beforeSend.bind(c),
      },
      onChange(value, text, selectedItem) {
        const selectedOptions = this.selectedOptions
        const dropdown = this
        c.dispatch('change', { detail: { value, text, selectedItem, selectedOptions, dropdown }})
      },
      onRemove(removedValue, removedText, $removedChoice) {
        const selectedOptions = this.selectedOptions
        const dropdown = this
        c.dispatch('change', { detail: { removedValue, removedText, $removedChoice, selectedOptions, dropdown }})
        $(this).trigger('change')
      },
      ...c.convertTemplate(),
      ...c.settingsValue,
    }
    
    let dropdown = $(c.element).dropdown(settings)
    c.dispatch('initialized', { detail: { dropdown, settings }})
  }

  beforeSend(settings) {
    const url = new URL(this.urlValue, window.location.origin)
    const params = new URLSearchParams(url.search)
    params.set('q', settings.urlData.query)
    if (this.hasParameterNameValue) {
      params.set(this.parameterNameValue, settings.urlData.query)
    }

    for (const [k, v] of Object.entries(this.queryParamsValue)) {
      if (Array.isArray(v)) {
        const key = k.endsWith('[]') ? k : `${k}[]`
        v.forEach(v => params.append(key, v))
      } else {
        params.set(k, v)
      }
    }

    url.search = params.toString()
    settings.url = url.href
    return settings
  }

  setQueryParam(k, v) {
    this.queryParamsValue = { ...this.queryParamsValue, [k]: v }

  }

  unsetQueryParam(k) {
    // eslint-disable-next-line no-unused-vars
    const { [k]: _, ...rest } = this.queryParamsValue
    this.queryParamsValue = rest
  }

  convertTemplate() {
    if (this.templateValue === "") return {}
    let template_function = this.templateValue.split('.')?.reduce( (acc, current) => acc && acc[current], window)
    if (typeof template_function === "function") {
      return { templates: { menu: template_function }}
    } else {
      return {}
    }
  }

  clear() {
    $(this.element).dropdown('clear')
  }

}
