$.fn.dropdown.settings.forceSelection = false;
$.fn.dropdown.settings.showOnFocus = true;
$.fn.dropdown.settings.saveRemoteData = false;
$.fn.search.settings.cache = false;

const dropdown = {
  init() {
    $('.ui.fixed.menu .dropdown').not(".custom").dropdown({
      placeholder: false,
      action: "hide",
      onShow: function() {
        general.check_menu()
      },
      onHide: function() {
        $(this).dropdown('clear');
      },
    });

    $('.ui.dropdown.noselection').dropdown({
      placeholder: false,
      action: "hide",
      onHide : function() {
        $(this).dropdown('clear');
      },
    });
  },
  
  init_action_menu() {
    $('.ui.actions.dropdown').dropdown({
      action: 'hide',
      onHide : function() {
        $(this).dropdown('clear');
      },
    });
  },
};

const dropdownTemplates = {
  search_menu(response, fields) {
    const values = response[fields.values] || {};
    let html = '';
    $.each(values, function(_index, option) {
      html += `<div class="item" data-value="${option[fields.value]}">${option[fields.name]}</div>`;
    });
    return html;
  },

  subchannel_search_menu(response, fields) {
    const values = response[fields.values] || {};
    let html = '';
    $.each(values, function(_index, option) {
      html += `<div class="item" data-value="${option[fields.value]}" data-carousel-value="${option.carousel_value}">${option[fields.name]}</div>`
    });
    return html;
  },

  asset_search_menu(response, fields) {
    const values = response[fields.values] || {};
    let html = '';
    $.each(values, function(_index, option) {
      html += `<div class="item" data-value="${option.a_ID}">`;
      html += '<div class="result"><div class="content">';
      html += `<div class="title">${option.a_ID} - ${option.a_Title} (${option.asset_type.aty_AssetType})</div>`;
      html += `<div class="description">${assets.missingcheck(option.a_AdditionalTitle)}</div>`;
      html += '</div></div></div>';
    });
    return html;
  },

  language_search_menu(response, fields) {
    const values = response[fields.values] || {};
    let html = '';
    $.each(values, function(_index, option) {
      html += `<div class="item" data-value="${option[fields.value]}" data-text="${option[fields.name]}">${option[fields.name]}</div>`;
    });
    return html;
  },

  territory_search_menu(response, fields) {
    const values = response[fields.values] || {};
    let html = '';
    $.each(values, function(_index, option) {
      html += `<div class="item" data-value="${option[fields.value]}" data-text="${option[fields.name]}"><i class="${option['code']} flag"></i>${option[fields.name]}</div>`;
    });
    return html;
  },
  
  project_territory_search_menu(response, fields) {
    const values = response[fields.values] || {};
    let html = '';
    $.each(values, function(_index, option) {
      html += `<div class="item" data-value="${option['code']}" data-text="${option[fields.name]}"><i class="${option['code']} flag"></i>${option[fields.name]}</div>`;
    });
    return html;
  },

  title_search_menu(response, fields) {
    const values = response[fields.values] || {};
    let html = '';
    $.each(values, function(_index, option) {
      let episodic = '';
      if (option['episodic']) {
        episodic = '<div class="ui inline right floated mini blue basic label">Episodic</div>';
      }
      html += `<div class="item" data-value="${option[fields.value]}" data-text="${option[fields.name]}"><div class="content"><div class="title">${episodic}${option[fields.name]}</div><div class="description">${option['english_name']} ${option['year']}</div></div></div>`;
    });
    return html;
  },

  workflow_search_menu(response, _fields) {
    let html = '';
    $.each(response.values, function(_index, option) {
      html += `<div class='item' data-value='${option.wjt_ID}' data-text='${option.wjt_Description}'> \
<div class='content'> \
<div class='title'> \
<div class='ui grid'> \
<div class='twelve wide column'>${option.wjt_Description} \
</div> \
<div class='four wide column'> \
<div class='ui inline mini basic label'>${option.workflow_type.wt_Name}</div> \
</div> \
</div> \
</div> \
</div> \
</div>`;
    });
    return html;
  },

  delivery_orders_search_menu(response, _fields) {
    let html = '';
    $.each(response.values, function(_index, option) {
      html += `<div class='item' data-value='${option.value}' data-text='${option.name}' data-seasonid='${option.seasonID}' data-contactid='${option.contactID}'> \
<div class='content'> \
<div class='title'> \
<div class='ui grid'> \
<div class='one wide column'>${option.value} \
</div> \
<div class='seven wide column'>${option.contact} \
</div> \
<div class='two wide column'>${option.channel} \
</div> \
<div class='five wide column'>${option.type} \
</div> \
</div> \
</div> \
</div> \
</div>`;
    });
    return html;
  },

  projects_search_menu(response, _fields) {
    let html = '';
    $.each(response.values, function(_index, option) {
      html += `<div class='item' data-value='${option.value}' data-text='${option.name}' data-platform='${option.platform}'> \
<div class='content'> \
<div class='title'> \
<div class='ui grid'> \
<div class='two wide column'>${option.value} \
</div> \
<div class='ten wide column'>${option.name} \
</div> \
<div class='four wide column'>${option.platform} \
</div> \
</div> \
</div> \
</div> \
</div>`;
    });
    return html;
  },

  asset_request_content_package_component_menu(response, _fields) {
    let html = '';
    $.each(response.values, function(_index, option) {
      html += `<div class='item' data-value='${option.value}' data-text='${option.name}'> \
<div class='content'> \
<div class='title'> \
<div class='ui grid'> \
<div class='two wide column'>${option.value} \
</div> \
<div class='twelve wide column'>${option.name} \
</div> \
</div> \
</div> \
</div> \
</div>`;
    });
    return html;
  },

  metadata_brand_cast_search(response, _fields) {
    let html = '';
    $.each(response.values, function(_index, option) {
      html += `<div class='item' data-value='${option.value}' data-text='${option.text}' data-middlename='${option.middlename}' data-firstname='${option.firstname}'> \
<div class='content'> \
<div class='title'>${option.text}</div> \
<div class='description'>${option.firstname}</div> \
</div> \
</div>`;
    });
    return html;
  },

  metadata_season_cast_search(response, _fields) {
    let html = '';
    $.each(response.values, function(_index, option) {
      html += `<div class='item' data-value='${option.value}' data-text='${option.text}' data-middlename='${option.middlename}' data-firstname='${option.firstname}'> \
<div class='content'> \
<div class='title'>${option.text}</div> \
<div class='description'>${option.firstname}</div> \
</div> \
</div>`;
    });
    return html;
  },

  metadata_title_cast_search(response, _fields) {
    let html = '';
    $.each(response.values, function(_index, option) {
      html += `<div class='item' data-value='${option.value}' data-text='${option.text}' data-middlename='${option.middlename}' data-firstname='${option.firstname}'> \
<div class='content'> \
<div class='title'>${option.text}</div> \
<div class='description'>${option.firstname}</div> \
</div> \
</div>`;
    });
    return html;
  },

  metadata_brand_season_search(response, _fields) {
    let html = '';
    $.each(response.values, function(_index, option) {
      html += `<div class='item' data-value='${option.value}' data-text='${option.text}' data-originaltitle='${option.originaltitle}' data-catalognumber='${option.catalognumber}' data-seasonnumber='${option.seasonnumber}' data-contact='${option.contact}' data-productionyear='${option.productionyear}' data-productioncountry='${option.productioncountry}'> \
<div class='content'> \
<div class='title'>${option.text}</div> \
<div class='description'>${option.originaltitle}</div> \
<div class='ui inline right description'>${option.catalognumber}</div> \
</div> \
</div>`;
    });
    return html;
  },

  metadata_season_episodes_search(response, _fields) {
    let html = '';
    $.each(response.values, function(_index, option) {
      html += `<div class='item' data-value='${option.value}' data-text='${option.text}' data-originaltitle='${option.originaltitle}' data-catalognumber='${option.catalognumber}' data-contact='${option.contact}' data-productionyear='${option.productionyear}' data-productioncountry='${option.productioncountry}'> \
<div class='content'> \
<div class='title'>${option.text}</div> \
<div class='description'>${option.originaltitle}</div> \
<div class='ui inline right description'>${option.catalognumber}</div> \
</div> \
</div>`;
    });
    return html;
  },

  metadata_brand_comment_search(response, _fields) {
    let html = '';
    $.each(response.values, function(_index, option) {
      html += `<div class='item' data-value='${option.value}' data-text='${option.text}' data-fullname='${option.fullname}'> \
<div class='content'> \
<div class='title'>${option.fullname}</div> \
<div class='description'>${option.text}</div> \
</div> \
</div>`;
    });
    return html;
  },

  metadata_season_comment_search(response, _fields) {
    let html = '';
    $.each(response.values, function(_index, option) {
      html += `<div class='item' data-value='${option.value}' data-text='${option.text}' data-fullname='${option.fullname}'> \
<div class='content'> \
<div class='title'>${option.fullname}</div> \
<div class='description'>${option.text}</div> \
</div> \
</div>`;
    });
    return html;
  },

  metadata_title_comment_search(response, _fields) {
    let html = '';
    $.each(response.values, function(_index, option) {
      html += `<div class='item' data-value='${option.value}' data-text='${option.text}' data-fullname='${option.fullname}'> \
<div class='content'> \
<div class='title'>${option.fullname}</div> \
<div class='description'>${option.text}</div> \
</div> \
</div>`;
    });
    return html;
  },

  metadata_title_rating_search(response, _fields) {
    let html = '';
    $.each(response.values, function(_index, option) {
      html += `<div class='item' data-value='${option.value}' data-text='${option.text}' data-ratingvalue='${option.ratingvalue}' data-system='${option.system}' data-description='${option.description}'> \
<div class='content'> \
<div class='title'>${option.text}</div> \
<div class='description'>${option.system}</div> \
</div> \
</div>`;
    });
    return html;
  },

  metadata_title_content_package_component_menu(response, fields) {
    const values = response[fields.values] || {};
    let html = '';
    $.each(values, function(_index, option) {
      html += `<div class='item' data-mtid='${option.mtid}' data-episode-number='${option.episode_number}' data-value='${option[fields.value]}' data-text='${option[fields.name]}'> \
<div class='content'> \
<div class='title'>${option[fields.name]}`
if (option.episode_number != null ) {
html += `<span class="ui basic tiny circular pull-right label" style="margin-top: 6px;">${option.episode_number}</span>` 
}
html += `</div><div class='description'>${option.catalog_no}</div> \
</div> \
</div>`;
    });
    return html;
  },

  distribution_channel_type_mapping_images(response, fields) {
    const values = response[fields.values] || {};
    let html = '';
    $.each(values, function(_index, option) {
      html += `<div class="item" data-value="${option["dctmi_ID"]}" data-text="${option["image_type_description"]}">${option["image_type_description"]}</div>`;
    });
    return html;
  },

  delivery_order_linked_order_menu(response, fields) {
    const values = response[fields.values] || {};
    let html = '';
    $.each(values, function(_index, option) {
      html += `<div class='item' data-value='${option[fields.value]}' data-text='${option[fields.name]}'><div class='content'><div class='title'>${option[fields.name]}</div><div class='description'>${option.description}</div></div></div>`;
    });
    return html;
  },

  pricelist_search_menu(response, fields) {
    const values = response[fields.values] || {};
    let html = '';
    $.each(values, function(_index, option) {
      html += `<div class="item" data-price="${option.price}" data-active="${option.active}" data-value="${option[fields.value]}">${option[fields.name]}</div>`;
    });
    return html;
  },

  season_id_catalog_no_search_menu(response, fields) {
    const values = response[fields.values] || {};
    let html = '';
    $.each(values, function(_index, option) {
      html += `<div class="item" data-seasoncatalogno="${option.seasoncatalogno}" data-brandcatalogno="${option.brandcatalogno}" data-value="${option[fields.value]}">${option[fields.name]}</div>`;
    });
    return html;
  },

  filelocation_search(response, fields) {
    const values = response[fields.values] || {};
    let html = '';
    $.each(values, function(_index, option) {
      html += `<div class="item" data-value="${option.value}" data-text="${option.name}" data-location="${option.location}" data-location-publish="${option.location_publish}" data-location-archive="${option.location_archive}" data-enforce-flat-root="${option.enforce_flat_root}" data-asset-transfer-account="${option.asset_transfer_account}" data-m2m-account="${option.m2m_account}" data-movement-location="${option.movement_location}">${option.name}</div>`;
    });
    return html;
  },

  qc_errorcode_search(response, _fields) {
    let html = '';
    $.each(response.values, function(_index, option) {
      html += `<div class="item" data-value="${option.value}" data-text="${option.name}" data-description="${option.description}" ">${option.name}</div>`;
    });
    return html;
  },
  asset_tag_search(response, _fields) {
    let html = ''
    let tag_list = response.values

    let tag_types = $('[data-tag-types]').data('tag-types')
    if (Array.isArray(tag_list) && typeof tag_list[0] === 'object' && tag_types.every(prop => !(prop in tag_list[0]))) {
      tag_list = [{ existing: tag_list }]
    }
    $.each(tag_list[0], function(category_name, tag_items) {  
      html += `<div class="header">${category_name}: </div>`
      $.each(tag_items, function(_a,option) {
        html += `<div class="item" data-value="${option.value}" data-text="${option.color_and_text}">${option.label}</div>`
      })
    })
    return html
  },
  distribution_channel_search(response, _fields) {
    let html = ''
    $.each(response.values, function(_a,option) {  
      html += `<div class="item" data-value="${option.value}" data-text="${option.name}">${option.name}</div>`
    })
    return html
  },
  distribution_channel_type_search(response, _fields) {
    let html = ''
    $.each(response.values, function(_a,option) {  
      html += `<div class="item" data-value="${option.value}" data-text="${option.name}">${option.name}</div>`
    })
    return html
  },
  distribution_subchannel_search(response, _fields) {
    let html = ''
    $.each(response.values, function(_a,option) {  
      html += `<div class="item" data-value="${option.value}" data-text="${option.name}">${option.name}</div>`
    })
    return html
  },

  queued_for_delivery_note(response, _fields) {
    let html = ''
    $.each(response.values, function(_a,option) {  
      html += `<div class="item" data-value="${option.value}" data-text="${option.text}">${option.text}</div>`
    })
    return html
  },
 
  workflow_job_preset_search(response, fields) {
    const values = response[fields.values] || {}
    let html = ''
    $.each(values, function(_index, option) {
      if (option.type == 'header') {
        html += '<div class="header">'
        if (option.icon)
          html += `<i class="${option.icon} icon"></i>`
        html += ` ${option.name} `
        html += '</div>'
        if (option.divider) {
          html += '<div class="divider"></div>'
        }
      } else {
      html += `
        <div class="item" data-value="${option.value}">
          <div class="result">
            <div class="content">
              <div class="title">${option.name}<span style="float: right; font-weight: 400; color: grey">(${option.preset_count} values)</span></div>`
      
      if (option.description) {
        html += `<div class="description" style='color: grey'>${option.description}</div>`
      }
      html += `<div class="hidden options" data-edit-enabled=${option.edit_enabled}></div>
            </div>
          </div>
        </div>
      `
      }
    })
    
    return html
  },

  upw(response, _fields) {
    let html = '';
    $.each(response.values, function(_index, option) {
      html += `<div class='item' data-value='${option.value}' data-text='${option.name}'>
        <div class="result">
          <div class='content'>
            <div class='title'>${option.name}</div>
            <div class='description'>${option.compnay}</div>
          </div>
        </div>
      </div>`
    })
    return html
  },

  workflow_job_preset_addition(addition) {
    return addition
  }


};

window.dropdown = dropdown;
window.dropdownTemplates = dropdownTemplates;
