import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {

  static targets = [
    'modal',
    'stepper',
    'externalAccountButton',
    'internalAccountButton',
    'nextButton',
    'userForm',
    'createButton',
  ]

  connect() {
  }

  createInternalAccount() {
    $(this.stepperTarget).find(".step[data-level=1]").addClass("completed")
    $(this.nextButtonTarget).removeClass("disabled")

    $(this.externalAccountButtonTarget).addClass("disabled")
    $(this.internalAccountButtonTarget).addClass("active")
  }

  nextButton() {
    //console.log($(this.nextButtonTarget).data("step"))

    switch($(this.nextButtonTarget).data("step")) {
      case 1:
        $(this.nextButtonTarget).addClass("loading")
        get($(this.internalAccountButtonTarget).data("path"), { responseKind: "turbo_stream" })
        break;
      case 2:
        $(this.stepperTarget).find(".step[data-level=3]").addClass("completed")  
        
        $(this.nextButtonTarget).addClass("loading")
        $(this.nextButtonTarget).data("step", 3)
        $(this.nextButtonTarget).hide()

        $(this.createButtonTarget).show()
        $(this.createButtonTarget).removeClass("disabled")
        
        break;
      default:
    }
  }

  userFormTargetConnected(element) {
    $(this.nextButtonTarget).removeClass("loading")
    $(this.stepperTarget).find(".step[data-level=2]").addClass("completed")
    $(this.nextButtonTarget).data("step", 2)
  }

  close(event) {
    if (event.detail.success) {
      $(this.modalTarget).modal('hide')
    }
  }

}
