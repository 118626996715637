document.addEventListener("turbo:load", function() {
  document.querySelector('#catalog_base_objects.index') && catalog_base_objects.index()
  document.querySelector('#catalog_base_objects.edit') && catalog_base_objects.edit()
  document.querySelector('#catalog_base_objects.show') && catalog_base_objects.show()
});

class CatalogBaseObject {
  index() {
    search.init("/catalog_base_objects");
    
    calendar.datepicker(".release_date_start", { position: 'top left', lastResort: 'top left' } )
    calendar.datepicker(".release_date_end", { position: 'top right', lastResort: 'top right' } )

    calendar.datepicker(".created_date_start", { position: 'top left', lastResort: 'top left' } )
    calendar.datepicker(".created_date_end", { position: 'top right', lastResort: 'top right' } )

    calendar.datepicker(".updated_date_start", { position: 'top left', lastResort: 'top left' } )
    calendar.datepicker(".updated_date_end", { position: 'top right', lastResort: 'top right' } )

    $(".import.eidr").on("click", function() {
      $(".import.eidr").addClass("loading")
      catalog_base_objects.import_modal()
    })

    $(".api.button").on("click", function() {
      catalog_base_objects.api_quickmenu()
    })
  }

  init_index_body() {
    general.quicklook_icon('#catalog_base_objects .quicklook')
    $('#catalog_base_objects .quicklook').on("click", function() {
      let element = $(this)
      element.find(".quicklook-icon").remove()
      element.append('<span class="quicklook-icon"><i class="fad fa-spinner-third fa-spin fa-w-16 fa-2x"></i></span>')
      catalog_base_objects.quicklook(element.data('model-id')).then(function() {
        element.find("i.icon").show()
        element.find(".quicklook-icon").remove()
      })
    });
  }

  quicklook(model_id) {
    return $.ajax({
      url: `/catalog_base_objects/${model_id}/quicklook`,
      data:{
        modal: true
      },
      success(data, _textStatus, _xhr) {

        $('body').append(data);
        
        $('.ui.quicklook.modal').modal({
          duration: 200,
          onShow() {
            catalog_base_objects.show()
          },
          onHidden() {
            $('body').find(".quicklook.modal").remove()
          }
        }).modal('show');
      },

      error(_jqXHR, _textStatus, errorThrown) {
        return console.log(errorThrown);
      }
    });
  }

  edit() {
    //catalog_base_objects.init_form("#catalog_base_objects.edit")
  }

  show(node = '#catalog_base_objects.show') {
    Array.from($(node).find('.remote.catalog_base_object.card')).map((card) => general.fetch_view(card))
    Array.from($(node).find('.remote.asset.card')).map((asset_card) => general.fetch_view(asset_card))
    Array.from($(node).find('.remote.asset_request.card')).map((asset_request_card) => general.fetch_view(asset_request_card))

    if ($(node).find('.ui.tab[data-tab=api]').length > 0) {
      catalog_base_objects.load_json()
    }
    if ($(node).find('.ui.tab[data-tab=visual]').length > 0) {
      catalog_base_objects.load_visual()
    }

    $(node).find(".output.html.segment .label").on("click", function() {
      catalog_base_objects.load_json()
    })

    $(node).find('.custom.item[data-tab]').tab({
      evaluateScripts: false,
      //alwaysRefresh: true,
      onVisible(path) {
        general.set_url_with_parameter("?tab=" + path)
        setTimeout(function() {
          CatalogBaseObject_reactFlowInstance.fitView({ padding: 0.2 });
        }, 100)

      }
    })

    $(node).find(".edit.button").on("click", function() {
      $(this).addClass("loading")
      catalog_base_objects.edit_base_object($(this).data("model-id"))
    })
  }

  init_form(node) {
    $(node).find('.dropdown').not(".remote").not(".custom").dropdown()

    const object_extras = $(node).find(".base_object_extras")
    const record_type = $(node).find('.dropdown.custom.record-type')
    const referent_type = $(node).find('.dropdown.custom.referent-type')

    record_type.dropdown({
      onChange(_value, _text, _$selectedItem) {
        if (record_type.dropdown("get value") != "" && record_type.dropdown("get value") != "") {
          object_extras.show()
        }
      }
    })
    referent_type.dropdown({
      onChange(_value, _text, _$selectedItem) {
        if (record_type.dropdown("get value") != "" && record_type.dropdown("get value") != "") {
          object_extras.show()
        }
      }
    })

    if (record_type.dropdown("get value") == "") {
      object_extras.hide()
    }

    const form_select = $(node).find(".new.object.select")

    if (form_select.length > 0) {
      if (record_type.dropdown("get value") == "") {
        const form_body = $(node).find(".form-body")
        form_body.hide()
        
        $(node).find(".new.object.select .primary.button").on('click', function() {
          //console.log($(this).attr("data-referent-id"))
          form_body.show()
          $(node).find(".new.object.select").parent().hide()
          referent_type.dropdown("set selected", $(this).attr("data-referent-id"))
          record_type.dropdown("set selected", $(this).attr("data-record-id"))
        })
      } else {
        form_select.parent().hide()
      }
    }

    catalog_base_objects.remove_card(node)

    calendar.datepicker(".release_date.field", { } )

    $(node).off("fields_added.nested_form_fields")
    $(node).on("fields_added.nested_form_fields", function(event, param) {
      $(event.target).find(".dropdown").dropdown()

      switch (param.object_class) {
        case "catalog_alternate_id":
          return
        case "catalog_alternate_resource_name":
          return
        case "catalog_relation":
          $(event.target).find(".remote.catalog_base_object.card").data("model-id", param.additional_data.catalog_base_object.id)
          $(event.target).find(".relates-to-id").val(param.additional_data.catalog_base_object.id)
          $(event.target).find(".dropdown").dropdown("set selected", param.additional_data.catalog_base_object.relation_type_id)
    
          Array.from($(node).find('.remote.catalog_base_object.card')).map((card) => general.fetch_view(card))
          return
        case "catalog_relation_child":
          $(event.target).find(".remote.catalog_base_object.card").data("model-id", param.additional_data.catalog_base_object.id)
          $(event.target).find(".base-object-id").val(param.additional_data.catalog_base_object.id)
          $(event.target).find(".relates-to-id").val($(node).find(".search.catalog_relations").data("model-id"))
          $(event.target).find(".dropdown").dropdown("set selected", param.additional_data.catalog_base_object.relation_type_id)
    
          Array.from($(node).find('.remote.catalog_base_object.card')).map((card) => general.fetch_view(card))
          return 
        case "catalog_series_info":
          calendar.datepicker(".end_date")
          return
        case "catalog_season_info":
          calendar.datepicker(".end_date")
          return
        case "catalog_episode_info":
          return
        case "catalog_sequence_info":
          return
        case "catalog_distribution_number":
          return
        case "catalog_house_number":
          return
        case "catalog_alternate_number":
          return
        case "catalog_compilation_info":
          catalog_base_objects.compilation_autocompletion(event.target)
          return
        case "catalog_compilation_entry":
          $(event.target).find(".remote.catalog_base_object.card").data("model-id", param.additional_data.catalog_base_object.id)
          $(event.target).find(".base-object-id").val(param.additional_data.catalog_base_object.id)

          Array.from($(event.target).find('.remote.catalog_base_object.card')).map((card) => general.fetch_view(card))
          return
        case "associated_asset":
          $(event.target).find(".remote.asset.card").data("model-id", param.additional_data.asset.a_ID)
          $(event.target).find(".asset-id").val(param.additional_data.asset.a_ID)

          Array.from($(event.target).find('.remote.asset.card')).map((card) => general.fetch_view(card))
          return
        case "associated_asset_request":
          $(event.target).find(".remote.asset_request.card").data("model-id", param.additional_data.asset_request.arq_ID)
          $(event.target).find(".asset-request-id").val(param.additional_data.asset_request.arq_ID)

          Array.from($(event.target).find('.remote.asset_request.card')).map((card) => general.fetch_view(card))
          
          return
        case "associated_project":
          $(event.target).find(".remote.project.card").data("model-id", param.additional_data.project.p_ID)
          $(event.target).find(".project-id").val(param.additional_data.project.p_ID)

          Array.from($(event.target).find('.remote.project.card')).map((card) => general.fetch_view(card))
          
          return
        case "catalog_manifestation_info":
          catalog_base_objects.manifestation_autocompletion(event.target)
          return
        default:
          return console.log(`Fields were successfully added, callback not handled for ${param.object_class}.`)
      }
    })

    general.fetch_view($(node).find('.remote.contact.card'))

    Array.from($(node).find('.remote.catalog_base_object.card')).map((card) => general.fetch_view(card))
    Array.from($(node).find('.remote.asset.card')).map((asset_card) => general.fetch_view(asset_card))
    Array.from($(node).find('.remote.asset_request.card')).map((asset_request_card) => general.fetch_view(asset_request_card))
    Array.from($(node).find('.remote.project.card')).map((project_pard) => general.fetch_view(project_pard))

    catalog_base_objects.contacts_autocompletion(node)

    catalog_base_objects.relation_autocompletion(node)
    catalog_base_objects.compilation_autocompletion(node)
    catalog_base_objects.manifestation_autocompletion(node)
    calendar.datepicker(".end_date")
  }

  create_base_object_from(parent_id) {
    const url = `/catalog_base_objects/${parent_id}/new_catalog_base_object_modal`

    $.ajax(url).done(function(data) {
      $("body").append(data)

      $('.edit-catalog_base_object.modal').modal({
        onShow() {
          catalog_base_objects.init_form(this)
          catalog_base_objects.send_form()
        },
        onHidden() {
          $('.edit-catalog_base_object.modal').remove()
        },
        onApprove: function() {
          
        },
        autofocus: false,
        closable: false
      }).modal("show")
    })
  }

  edit_base_object(model_id) {
    const url = `/catalog_base_objects/${model_id}/edit_catalog_base_object_modal`

    $.ajax(url).done(function(data) {
      $("body").append(data)

      $('.edit-catalog_base_object.modal').modal({
        onShow() {
          catalog_base_objects.init_form(this)
          catalog_base_objects.send_form()
        },
        onHidden() {
          $('.edit-catalog_base_object.modal').remove()
        },
        onApprove: function() {
          
        },
        autofocus: false,
        closable: false,
        observeChanges: true
      }).modal("show")
    })
  }

  add_base_object_relation(model_id) {
    const url = `/catalog_base_objects/${model_id}/add_relations_modal`

    $.ajax(url).done(function(data) {
      $("body").append(data)

      $('.edit-catalog_base_object.modal').modal({
        onShow() {
          catalog_base_objects.init_form(this)
          catalog_base_objects.send_form()
        },
        onHidden() {
          $('.edit-catalog_base_object.modal').remove()
        },
        onApprove: function() {
          
        },
        autofocus: false,
        closable: false
      }).modal("show")
    })
  }

  remove_base_object_relation_modal(model_id) {
    const url = `/catalog_base_objects/${model_id}/remove_relation_modal`

    $.ajax(url).done(function(data) {
      $("body").append(data)
      const modal = $('.modal.remove.relation')
      modal.modal({
        onShow() {
          const button = $(".ui.green.remove.button")
          button.on("click", function() {
            button.addClass("loading")

            $.ajax({
              url: `/catalog_base_objects/${model_id}/remove_relation`,
              type: "delete",
              success(_data, _textStatus, _xhr) {
                button.removeClass("loading")
                modal.modal("hide")
                catalog_base_objects.load_json()
                catalog_base_objects.load_visual()
              },
              error(_jqXHR, _textStatus, errorThrown) {
                button.removeClass("loading")
                return console.log(errorThrown);
              }
            })
          })
        },
        onHidden() {
          modal.remove()
        }
      }).modal("show")
    })
  }

  send_form() {
    let form_id = $("button.edit-catalog_base_object").attr('form')

    $(`#${form_id}`).off('turbo:submit-start')
    $(`#${form_id}`).on('turbo:submit-start', function(_e, _data, _status, _xhr) {
      //console.log("submit-start")
      $('.edit-catalog_base_object.button').addClass('loading')
    })

    $(`#${form_id}`).off('turbo:submit-end')
    $(`#${form_id}`).on('turbo:submit-end', function(event, _data, _status, _xhr) {
      //console.log("submit-end")
      $('.edit-catalog_base_object.button').removeClass('loading')

      if(event.detail.success) {
        $('.edit-catalog_base_object.modal').modal('hide')
        catalog_base_objects.load_visual()
        catalog_base_objects.load_json()
      }
    })

    $(`#${form_id}`).off('ajax:beforeSend.catalog_base_object')
    $(`#${form_id}`).on('ajax:beforeSend.catalog_base_object', function(_e, _data, _status, _xhr) {
      //console.log("beforeSend")
      $('.edit-catalog_base_object.button').addClass('loading')
    })

    $(`#${form_id}`).off('ajax:complete.catalog_base_object')
    $(`#${form_id}`).on('ajax:complete.catalog_base_object', function(_event,_xhr,_options) {
      //console.log("complete")
      $('.edit-catalog_base_object.button').removeClass('loading')
    })

    $(`#${form_id}`).off('ajax:success.catalog_base_object')
    $(`#${form_id}`).on('ajax:success.catalog_base_object', function(_response) {
      //console.log("success")
      $('.edit-catalog_base_object.modal').modal('hide')
      catalog_base_objects.load_visual()
      catalog_base_objects.load_json()
    })

    $(`#${form_id}`).off("ajax:error.catalog_base_object")
    $(`#${form_id}`).on("ajax:error.catalog_base_object", function(data) {
      //console.log("error")
      const errors = data.originalEvent.detail[0] 
      let message = ""
      console.log(errors)
      for (const key in errors) {
        message += `<li>${key}: ${errors[key]}</li>`
      }
      $('body').toast({ 
        class: 'error', 
        className: {
          toast: 'ui message'
        }, 
        title: 'Error saving Base Object', 
        message: message
      })
    })
  }

  load_json() {
    $("#output").html("<div class='ui active centered index inline loader'></div>")
    $.ajax({
      url: `/catalog_base_objects/${$('#catalog_base_object_id').val()}.json`,
      dataType: "json",
    }).done(function(data, _textStatus, _request) {
      //console.log(request.getAllResponseHeaders())
      $("#output").empty()
      $("#output").text(JSON.stringify(data, null, 2))
      return $('code#output').each(function(_i, block) {
        hljs.highlightElement(block)
      });
    }).fail(function(data) {
      $("#output").empty()
      $("#output").html("<center><div class='ui red basic label'>" + data.statusText + "</div></center>")
    })
  }

  load_visual() {
    const visual_tab = $('.ui.tab[data-tab=visual]')
    visual_tab.html("<div class='ui active centered index inline loader'></div><br><br>")
    return $.ajax({
      url: `/catalog_base_objects/${$('#catalog_base_object_id').val()}/visual`,
    }).done(function(data) {
      visual_tab.empty()
      visual_tab.html(data)
      
      ReactRailsUJS.mountComponents("#visual-react")
    }).fail(function(data) {
      visual_tab.empty()
      visual_tab.html("<br><center><div class='ui red basic label'>" + data.statusText + "</div></center><br>")
    })
  }

  import_modal(model_id = null) {
    const url = `/catalog_base_objects/import_modal`
    $.ajax({
        url, data: { model_id }
      }).done(function(data) {
        $(".import.eidr").removeClass("loading")
      $("body").append(data)

      let modal = $('.import.modal')

      modal.modal({
        autofocus: false,
        onShow() {

          modal.find('.item[data-tab]').tab()
          modal.find('.item[data-tab]').on('click', function() {
            modal.find('.sub.header').text(`from ${$(this).text()}`)
          })

          catalog_base_objects.import_search(modal)
          let search_field = $(modal).find(".search.import_search")
          $('.import.modal .ui.dropdown').dropdown({
            onChange(_value, _text, _$choice) {
              search_field.search("cancel query")
              search_field.search("query")
            }
          })
        },
        onHidden() {
          modal.remove()
        },
        onApprove: function() {
          catalog_base_objects.import_button()

          return false
        },
        observeChanges: true
      }).modal("show")
    })
  }

  import_owner_id() {
    const import_owner_id = $("#import_owner_id").val()
    
    if (import_owner_id != "") {
      return parseInt(import_owner_id)
    }
    return null
  }

  sort_catalog_objects(cbos_data) {
    return fetch("/catalog_base_objects/sort_import_relations.json", {
      method: "POST",
      body: JSON.stringify({ catalog_base_objects: cbos_data }),
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    }).then(function(response) {
      const data = response.json();
      return data;
    })
  }

  async import_button() {
    $(".import.modal .scrolling.content .relations.button").remove()
    $('.import.modal .import.button').addClass('disabled loading')
    $(".import.modal .selector").off('click')

    const cbos = $('.import.modal .import_card').has('svg.selected')
    // map all base objects to an array
    const cbos_data = Array.from(cbos.map( (_, cbo) => $(cbo).data('catalog-base-object') ))
    // fetch sorted base objects array
    const relation_groups = await catalog_base_objects.sort_catalog_objects(cbos_data)

    const results = []

    for (const group of relation_groups) {
      for (const catalog_base_object of group) {
        const item = $(".import_card[data-content-id='" + catalog_base_object.content_id + "']")
        const import_owner_id = catalog_base_objects.import_owner_id()
        if (import_owner_id != null) {
          catalog_base_object.owner_id = import_owner_id
        }

        const response = await post('catalog_base_objects.json', { body: JSON.stringify({ catalog_base_object: catalog_base_object }) }) 
        if (response.ok) {
          $(item).find('.selector').hide()
          $(item).find('.list.item .success-symbol').show()
        } else {
          const responseJSON = await response.json
          const segment = $(item).find('.tiny.segment')
          segment.addClass('attached')
          let error_message = '<ul style="margin: 0;">'
          Object.keys(responseJSON).forEach((key) => {
            error_message += `<li>${key} ${responseJSON[key]}</li>`
          })
          error_message += '</ul>'
          segment.parent().append(`<div class="ui bottom attached error message"><div class="content">${error_message}</div>`)
          $(item).find('.selector').hide()
          $(item).find('.list.item .error-symbol').show()
        }
        results.push(await response)
      }
    }

    $('.import.modal .import.button').removeClass('loading')
    if ( results.filter((result) => result.ok === false).length > 0 ) {
      $('.import.modal .import.button').removeClass('green loading').addClass('red')
      $('.import.modal .import.button').text("Import failed!")
    } else {
      $('.import.modal .import.button').removeClass('disabled')
      $('.import.modal .import.button').text("Ok")
      $('.import.modal .import.button').on('click', () => { 
        $('.import.modal').modal('hide') 
        location.reload()
      })
      $('.import.modal .cancel.button').remove()
      catalog_base_objects.load_visual()
      catalog_base_objects.load_json()
    }
  }

  api_quickmenu() {
    $('body').dimmer({
      dimmerName: "quickmenu",
      onShow() {
        $(".ui.dimmer.quickmenu").append("<div id='quickmenu' class='open animate__animated animate__slideInRight'><div class='quickmenu header'></div><div class='quickmenu body'><span class='center'><br><br><br><div class='fa-3x'><i class='far fa-circle-notch fa-spin'></i></div></span></div></div>");
        $("#quickmenu .quickmenu.header").append("<h3 class='ui header'><i class='fa-solid fa-brackets-curly'></i> <span>&nbsp; API</span></h3>");
        catalog_base_objects.load_api_menu()
      },
      onHide() {
        $("#quickmenu").removeClass("animate__animated animate__slideInRight")
        $("#quickmenu").addClass("animate__animated animate__slideOutRight")
        setTimeout(function() {
          $(".ui.dimmer.quickmenu").remove();
          $("body").removeClass("dimmable dimmed")
        }, 800)
      }
    }).dimmer('show')
  }

  load_api_menu() {
    return $.ajax({
      url: `/catalog_base_objects/api_menu`,
    }).done((data) => {
      $("#quickmenu .quickmenu.body").html(data)
    }).fail((_data) => {
      $("#quickmenu .quickmenu.body").html("<br><br><span class='center'><div class='ui red basic label'>Loading Error</div></span>")
    })
  }

  import_search(node) {
    catalog_base_objects.eidr_search_field(node)
    catalog_base_objects.cmls_search_field(node)
  }

  eidr_search_field(node) {
    let search_field = $(node).find(".eidr.search.import_search")

    if ($(node).data("card") == true && $(node).data("parent") == true && search_field.find("input").val() != "") {
      catalog_base_objects.add_import_card(search_field.find("input").val(), "eidr")
    } else if ($(node).data("card") == true && $(node).data("parent") == false && search_field.find("input").val() != "") { 
      $(".import.modal .scrolling.content").html("<div class='relations list' data-content-id=" + search_field.find("input").val() + "></div>")
      catalog_base_objects.load_relations(search_field.find("input").val(), "edir")
    } else {
      search_field.search("destroy")

      const url = '/catalog_base_objects/import_search.json?title={/query}';
      search_field.search({
        cache: false,
        minCharacters: 2,
        apiSettings: {
          url,
          data: {
            connector: "eidr",
            referent_type: $('.import.modal #referent_type_filter').val(),
            record_type: $('.import.modal #record_type_filter').val()
          },
          beforeSend(settings) {
            settings.data.referent_type = $('.import.modal #referent_type_filter').val()
            settings.data.record_type = $('.import.modal #record_type_filter').val()
            return settings;
          }
        },
        templates: {
          standard: catalog_base_objects.eidr_search
        },
        onSelect(result, _response) {
          const content_id = result.ID
          
          search_field.search("hide results")
          search_field.search("set value", "")
  
          catalog_base_objects.add_import_card(content_id, "eidr")
        }
      })
    }
  }

  cmls_search_field(node) {
    let search_field = $(node).find(".cmls.search.import_search")

    search_field.search("destroy")

    const url = '/catalog_base_objects/import_search.json?title={/query}';
    search_field.search({
      cache: false,
      minCharacters: 2,
      apiSettings: {
        url,
        data: {
          connector: "cmls",
        }, 
        beforeSend(settings) {
          return settings;
        }
      },
      templates: {
        standard: catalog_base_objects.cmls_search
      },
      onSelect(result, _response) {
        const content_id = result.id

        search_field.search("hide results")
        search_field.search("set value", "")

        catalog_base_objects.add_import_card(content_id, "cmls")
      }
    })
  }

  placeholder_card() {
    return "<div class='ui tiny segment' style='margin-bottom: 12px;'><div class='card list item' style='height:62px; padding:0.4em 1em'><div class='ui placeholder' style='position: relative; top: 8px; width: 500px; height: 36px;'><div class='image header'><div class='line'></div><div class='line'></div></div></div></div></div>"
  }

  add_import_card(content_id, connector) {
    $(".import.modal .scrolling.content").html(catalog_base_objects.placeholder_card())
    $.ajax({
      url: `/catalog_base_objects/card_from_import`,
      data: {
        content_id: content_id,
        owner_id: catalog_base_objects.import_owner_id(),
        connector: connector
      }
    }).done(function(data) {
      $(".import.modal .scrolling.content").html(data)
      $('.import.modal .import.button').removeClass('disabled')
      $('.import.modal .ui.checkbox').checkbox({
        onChange: function() {
         if ($('.import.modal .checked.checkbox').length > 0) {
          $('.import.modal .import.button').removeClass('disabled')
         } else {
          $('.import.modal .import.button').addClass('disabled')
         }
        }
      })
      catalog_base_objects.init_load_relations_button()
      $('.import.modal .show-field-settings').on('click', () => {
        catalog_base_objects.show_import_field_setting_modal()
      })
      catalog_base_objects.init_selectors()
      catalog_base_objects.check_import_button()
    }).fail(function(_data) {
      
    })
  }

  init_load_relations_button() {
    $(".import.modal .scrolling.content .relations.button").off("click")
    $(".import.modal .scrolling.content .relations.button").on("click", function() {
      $(this).addClass("loading disabled")
      catalog_base_objects.load_relations($(this).data('content-id'), $(this).data('connector'))
    })
  }

  init_selectors() {
    $('.import.modal .list.item .symbol').hide()
    $('.import.modal .selector').show()
    $(".import.modal .selector").off('click')
    $(".import.modal .selector").on('click', function() {
      if ($(this).find(".selected").length > 0) {
        index_select.unselect(this)
      } else {
        index_select.select(this)
      }
      catalog_base_objects.check_import_button()
    })
  }

  check_import_button() {
    if ($('.import.modal .selector .selected').length > 0) {
      $('.approve.import.button').removeClass('disabled')
    } else {
     $('.approve.import.button').addClass('disabled')
    }
  }

  load_relations(content_id, connector) {
    return $.ajax({
      url: `/catalog_base_objects/import_relations`,
      dataType: "json",
      data: {
        content_id: content_id,
        connector: connector
      }
    }).done(function(data) {
      const list = $(`.import.modal .scrolling.content .relations.list[data-content-id="${content_id}"`)
      $(`.import.modal .scrolling.content .relations.button[data-content-id="${content_id}"`).remove()

      switch (connector) {
        case "eidr":
          list.prepend(`<div class='ui dividing header' style='margin: 4px;'>${data.totalMatches} Relations</div>`)
          var promises = data.results.map((result) => {
            list.append(`<div class='card-target' data-content-id='${result.ID}'>${catalog_base_objects.placeholder_card()}</div>`)
            return $.ajax({
              url: `/catalog_base_objects/card_from_import`,
              data: {
                content_id: result.ID,
                owner_id: catalog_base_objects.import_owner_id(),
                connector: "eidr",
              }
            }).done(function(data) {
              list.find(".card-target[data-content-id='" + result.ID + "']").html(data)
            }).fail(function(_data) { })
          })
          Promise.allSettled(promises).then(() => { 
            catalog_base_objects.init_selectors()
            catalog_base_objects.init_load_relations_button()
          })
          
          break;
        case "cmls":
          list.prepend(`<div class='ui dividing header' style='margin: 4px;'>1 Relation</div>`)
          list.append(`<div class='card-target' data-content-id='${data.content_id}'>${catalog_base_objects.placeholder_card()}</div><br>`)
          
          $.ajax({
            url: `/catalog_base_objects/card_from_import`,
            data: {
              content_id: data.content_id,
              owner_id: catalog_base_objects.import_owner_id(),
              connector: "cmls",
            }
          }).done(function(data2) {
            list.find(`.card-target[data-content-id='${data.content_id}']`).html(data2)
            catalog_base_objects.init_selectors()
            catalog_base_objects.init_load_relations_button()
          }).fail(function(_data) { })
          
          break;
      }

    }).fail(function(_data) {
      $(`.import.modal .scrolling.content .relations.button[data-content-id="${content_id}"`).removeClass("loading")
    })
  }

  show_import_field_setting_modal() {
    console.log("show modal")
  }

  contacts_autocompletion(node) {
    let search_field = $(node).find(".search.contacts")
    search_field.search("destroy")
    
    const url = '/contacts.json?search_input={/query}';
    return search_field.search({
      cache: false,
      minCharacters: 2,
      apiSettings: {
        url,
      },
      fields: {
        results: "contacts"
      },
      templates: {
        standard: contacts.contacts_search
      },
      onResultsAdd(_html) {
        setTimeout(function() {
          Array.from($(search_field).find('.remote.contact.card')).map((card) => general.fetch_view(card))
        }, 100)
        return true
      },
      onSelect(result, _response) {
        //console.log(result)

        $("#catalog_base_object_owner_id").val(result.id)

        search_field.search("hide results")
        search_field.search("set value", "")

        search_field.closest(".field").find(".ui.fluid.search").hide()

        search_field.closest(".field").find(".tiny.segment .remote.contact.card").data("model-id", result.id)

        general.fetch_view($(node).find('.remote.contact.card'))
        
        search_field.closest(".field").find(".tiny.segment").show()
      }
    })
  }

  relation_autocompletion(node) {
    let search_field = $(node).find(".search.catalog_relations")
    search_field.search("destroy")
    
    const url = '/catalog_base_objects.json?search_input={/query}&q={/query}&with_relation_type_id=true';
    return search_field.search({
      cache: false,
      minCharacters: 2,
      apiSettings: {
        url,
      },
      fields: {
        results: "catalog_base_objects"
      },
      templates: {
        standard: catalog_base_objects.base_objects_search
      },
      onResultsAdd(_html) {
        setTimeout(function() {
          Array.from($(search_field).find('.remote.catalog_base_object.card')).map((card) => general.fetch_view(card))
        }, 100)
        return true
      },
      onSelect(result, _response) {
        $(node).find('.search.catalog_relations .add_nested_fields_link').trigger('click', [{
          catalog_base_object: result
        }])

        if($(".placeholder.segment.relations").length > 0) {
          $(".placeholder.segment.relations").remove()
        }

        search_field.search("hide results")
        search_field.search("set value", "")
      }
    })
  }

  compilation_autocompletion(node = "#catalog_base_objects") {
    let search_field = $(node).find(".search.catalog_compilation_entries")
    search_field.search("destroy")

    const url = '/catalog_base_objects.json?search_input={/query}&q={/query}';
    return search_field.search({
      cache: false,
      minCharacters: 2,
      apiSettings: {
        url,
        data: {
          compilation_class_filter: $(node).find(".search.compilation-class").dropdown('get value')
        },
        beforeSend(settings) {
          settings.data.compilation_class_filter = $(node).find(".search.compilation-class").dropdown('get value')
          return settings
        }
      },
      fields: {
        results: "catalog_base_objects"
      },
      templates: {
        standard: catalog_base_objects.base_objects_search
      },
      onResultsAdd(_html) {
        setTimeout(function() {
          Array.from($(search_field).find('.remote.catalog_base_object.card')).map((card) => general.fetch_view(card))
        }, 100)
        return true
      },
      onSelect(result, _response) {
        $(node).find('.search.catalog_compilation_entries .add_nested_fields_link').trigger('click', [{
          catalog_base_object: result
        }])

        search_field.search("hide results")
        search_field.search("set value", "")
      }
    })
  }

  base_objects_search(response) {
    let html = '';
    $.each(response.catalog_base_objects, function(_index, option) {
      html += '<div class="result" style="padding: 0;"><div class="content">';
      html += `<div class="remote catalog_base_object card" data-model-name="catalog_base_objects" data-model-id="${option.id}" data-type="list" data-fields="no-links" style="margin-top: 10px;"><div class="card list item" style="height: 62px; padding: 0.4em 1em;"><div class="ui placeholder"><div class="line"></div><div class="line"></div></div></div></div>`
      html += '</div></div>';
    });
    return html;
  }

  eidr_search(response) {
    let html = ''
    $.each(response.results, function(_index, option) {
      let language = "und"
      language = option?.OriginalLanguage?.find( language => language._type === 'primary' )?.value || option?.OriginalLanguage[0]?.value
      html += `
      <div class="result" style="padding: 0;">
        <div class="content">
          <div class="small list item" style="height: 62px; padding: 0.4em 1em;">
            <div class="title" style="top:8px; left:10px;">
              <div class="main-title">
                <b>${option.ResourceName.value}</b>
              </div>
              <div class="additional-title">${option.ID}</div> 
              <div class="locations">
                <div class="row">
                  <div class='ui basic mini label'>${option.StructuralType}</div>
                </div>
                <div class="row" style="margin-top: 2px;">
                  <div class='ui basic mini label'>${option.ReferentType}</div>
                </div>
              </div>

              <div class="delimiter" data-position="left center">
                <div class="line" style="height:40px;"></div>
              </div>

              <div class="ui equal width grid technical">
                <div class="column">
                  <div class="collapsing">Release Date <br><b>${option.ReleaseDate}</b></div>
                </div>
                <div class="column">
                  <div class="collapsing">Length <br><b>${option.ApproximateLength}</b></div>
                </div>
                <div class="column">
                  <div class="collapsing">Language <br><b>${language}</b></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>`
    })
    return html;
  }

  cmls_search(response) {
    let html = ''
    $.each(response.results, function(_index, option) {
      html += `
      <div class="result" style="padding: 0;">
        <div class="content">
          <div class="small list item" style="height: 62px; padding: 0.4em 1em;">
            <div class="title" style="top:8px; left:10px;">
              <div class="main-title">
                <b>${option.title}</b>
              </div>
              <div class="additional-title">${option.id}</div> 
              <div class="locations">
                <div class="row">
                  <div class='ui basic mini label'>${option.vodType}</div>
                </div>
                <div class="row" style="margin-top: 2px;">
                  <div class='ui basic mini label'>${option.type}</div>
                </div>
              </div>

              <div class="delimiter" data-position="left center">
                <div class="line" style="height:40px;"></div>
              </div>

              <div class="ui equal width grid technical">
                <div class="column">
                  <div class="collapsing">Release Date <br><b>${option.year}</b></div>
                </div>
                <div class="column">
                  <div class="collapsing">Length <br><b>${option.duration}</b></div>
                </div>
                <div class="column">
                  <div class="collapsing">Language <br><b></b></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>`
    })
    return html;
  }

  manifestation_autocompletion(node = "#catalog_base_objects") {

    let search_field_asset = $(node).find(".search.manifest-assets")
    search_field_asset.search("destroy")

    const url = '/assets/search_dropdown?q={/query}&search_input={/query}';
    search_field_asset.search({
      cache: false,
      minCharacters: 3,
      apiSettings: {
        url
      },
      templates: {
        standard: search.asset_basic_search
      },
      onSelect(result, _response) {
        $(node).find('.search.manifest-assets .add_nested_fields_link').trigger('click', [{ asset: result }])

        search_field_asset.search("hide results")
        search_field_asset.search("set value", "")
      }
    });

    let search_field = $(node).find(".search.manifest-asset_requests")
    search_field.search("destroy")

    const arq_url = '/asset_requests/search/import/?q={/query}';
    search_field.search({
      cache: false,
      minCharacters: 3,
      apiSettings: {
        url: arq_url,
        data: {
          filter: $('#filter_arq').prop("checked")
        },
        beforeSend(settings) {
          settings.data.filter = $('#filter_arq').prop("checked");
          return settings;
        }
      },
      templates: {
        standard: search.asset_asset_request_search
      },
      onSelect(result, _response) {
        $(node).find('.search.manifest-asset_requests .add_nested_fields_link').trigger('click', [{
          asset_request: result
        }])

        search_field.search("hide results")
        search_field.search("set value", "")
      }
    });

    let search_field_project = $(node).find(".search.manifest-projects")
    search_field_project.search("destroy")

    const project_url = '/projects/?q={/query}';
    search_field_project.search({
      cache: false,
      minCharacters: 3,
      apiSettings: {
        url: project_url,
      },
      templates: {
        standard: search.project_search
      },
      onSelect(result, _response) {
        //console.log($(node).find('.search.manifest-projects .add_nested_fields_link'))
        $(node).find('.search.manifest-projects .add_nested_fields_link').trigger('click', [{ project: result }])

        search_field_project.search("hide results")
        search_field_project.search("set value", "")
      }
    });
  }

  remove_card(node) {
    $(node).find('.label.remove').on('click', function() {
      $(this).closest(".field").find("input[type=hidden]").val("")
      $(this).closest(".field").find(".ui.fluid.search").show()
      $(this).closest(".tiny.segment").hide()
    })
  }

}

window.catalog_base_objects = new CatalogBaseObject()
