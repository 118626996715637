document.addEventListener("turbo:load", function() {
  document.querySelector('#validations.index') && validations.index()
  document.querySelector('#validations.edit') && validations.edit()
  document.querySelector('#validations.show') && validations.show()
  document.querySelector('.show_validation_results_modal') && validations.init_validation_results_modal()
})

var validations = {

  index() {
    if ($("#validations.index").length > 0) {
      general.init_common()
      search.init("/validations")
    }
  },

  edit() {
  },

  init_form(node) {
    $(node).find('.dropdown').not(".remote").not(".custom").dropdown()
    calendar.datetimepicker(node)
  },

  init_validation_results_modal() {
    $(".show_validation_results_modal").off('click.validations')
    $(".show_validation_results_modal").on('click.validations', function() {
      validations.show_validation_results_modal(this)
    })
  },

  init_show_validation_details() {
    $('.show_validation_details.button').off('click.validations')
    $('.show_validation_details.button').on('click.validations', function() {
      validations.show_validation_details_modal(this)
    })
  },

  show_validation_results_modal(target) {
    if (!target.querySelector('.loader')) {
      let url = target.dataset.url
      if (url) {
        target.insertAdjacentHTML('beforeend', `<div class="ui active mini inline loader"></div>`)

        fetch(url)
        .then( response => response.text())
        .then( modal_html => { 
          let modal = $(modal_html).appendTo('body')
          modal.modal({
            allowMultiple: true,
            onHidden() {
              modal.remove()
            }
          })
          modal.modal("show")
          validations.init_show_validation_details()
        }).catch((e) => {
          console.log(e)
        }).finally(() => {
          target.querySelector('.loader')?.remove()
        })
      }
    }
  },

  show_validation_details_modal(target) {
    if (!target.querySelector('.loader')) {
      let url = target.dataset.url
      if (url) {
        target.insertAdjacentHTML('beforeend', `<div class="ui active mini inline loader"></div>`)

        fetch(url)
        .then( response => response.text())
        .then( modal_html => { 
          let modal = $(modal_html).appendTo('body')
          modal.modal({
            allowMultiple: true,
            onHidden() {
              modal.remove()
            }
          })
          modal.modal("show")
        }).catch((e) => {
          console.log(e)
        }).finally(() => {
          target.querySelector('.loader')?.remove()
        })
      }
    }
  }
}

window.validations = validations
